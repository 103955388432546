var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c(
        "c-search-box",
        {
          ref: "searchbox",
          attrs: { isShowSearch: false },
          on: { enter: _vm.getList },
        },
        [
          _c("template", { slot: "search" }, [
            _c(
              "div",
              { staticClass: "col-xs-12 col-sm-12 col-md-3 col-lg-3 col-xl-3" },
              [
                _c("c-datepicker", {
                  attrs: {
                    range: true,
                    defaultStart: "-1y",
                    defaultEnd: "2M",
                    label: "공사기간",
                    name: "period",
                  },
                  model: {
                    value: _vm.searchParam.period,
                    callback: function ($$v) {
                      _vm.$set(_vm.searchParam, "period", $$v)
                    },
                    expression: "searchParam.period",
                  },
                }),
              ],
              1
            ),
            _c(
              "div",
              { staticClass: "col-xs-12 col-sm-12 col-md-3 col-lg-3 col-xl-3" },
              [
                _c("c-select", {
                  attrs: {
                    type: "search",
                    comboItems: _vm.finishFlagItems,
                    itemText: "codeName",
                    itemValue: "code",
                    name: "constructionFinishFlag",
                    label: "공사 진행상태",
                  },
                  model: {
                    value: _vm.searchParam.constructionFinishFlag,
                    callback: function ($$v) {
                      _vm.$set(_vm.searchParam, "constructionFinishFlag", $$v)
                    },
                    expression: "searchParam.constructionFinishFlag",
                  },
                }),
              ],
              1
            ),
          ]),
        ],
        2
      ),
      _c("c-table", {
        ref: "table",
        attrs: {
          title: "공사 목록",
          filtering: false,
          columnSetting: false,
          isFullScreen: false,
          columns: _vm.grid.columns,
          data: _vm.grid.data,
          gridHeight: _vm.grid.height,
          rowKey: "sopConstructionId",
          selection: _vm.popupParam.type,
        },
      }),
      _c("div", { staticClass: "space-bottom-button" }),
      _c(
        "div",
        { staticClass: "search-box-btn" },
        [
          _c(
            "q-btn-group",
            { attrs: { outline: "" } },
            [
              _c("c-btn", {
                attrs: { label: "검색", icon: "search" },
                on: { btnClicked: _vm.getList },
              }),
              _c("c-btn", {
                attrs: { label: "선택", icon: "check" },
                on: { btnClicked: _vm.select },
              }),
            ],
            1
          ),
        ],
        1
      ),
      _c("c-dialog", { attrs: { param: _vm.popupOptions } }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }