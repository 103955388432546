<template>
  <div>
    <c-search-box ref="searchbox" :isShowSearch="false" @enter="getList">
      <template slot="search">
        <div class="col-xs-12 col-sm-12 col-md-3 col-lg-3 col-xl-3">
          <c-datepicker
            :range="true"
            defaultStart="-1y"
            defaultEnd="2M"
            label="공사기간"
            name="period"
            v-model="searchParam.period"
          />
        </div>
        <div class="col-xs-12 col-sm-12 col-md-3 col-lg-3 col-xl-3">
          <c-select
            type="search"
            :comboItems="finishFlagItems"
            itemText="codeName"
            itemValue="code"
            name="constructionFinishFlag"
            label="공사 진행상태"
            v-model="searchParam.constructionFinishFlag"
          ></c-select>
        </div>
      </template>
    </c-search-box>
    <c-table
      ref="table"
      title="공사 목록"
      :filtering="false"
      :columnSetting="false"
      :isFullScreen="false"
      :columns="grid.columns"
      :data="grid.data"
      :gridHeight="grid.height"
      rowKey="sopConstructionId"
      :selection="popupParam.type"
    >
    </c-table>
    <div class="space-bottom-button"></div>
    <!-- 버튼 영역 -->
    <div class="search-box-btn">
      <q-btn-group outline >
        <c-btn label="검색" icon="search" @btnClicked="getList" />
        <c-btn label="선택" icon="check" @btnClicked="select" />
      </q-btn-group>
    </div>
    <c-dialog :param="popupOptions"></c-dialog>
  </div>
</template>

<script>
import selectConfig from '@/js/selectConfig';
export default {
  name: 'constructionPop',
  props: {
    popupParam: {
      type: Object,
      default: function() {
        return {
          type: 'single',
        }
      },
    },
    contentHeight: {
      type: [String, Number],
      default: function() {
        return 'auto'
      },
    }
  },
  data() {
    return {
      searchParam: {
        period: [],
        constructionFinishFlag: 'N'
      },
      grid: {
        columns: [
          {
            name: 'constructionName',
            field: 'constructionName',
            label: '공사명',
            align: 'left',
            style: 'width:220px',
            sortable: true,
          },
          {
            name: 'constructionPeriod',
            field: 'constructionPeriod',
            label: '공사기간',
            align: 'center',
            style: 'width:180px',
            sortable: true,
          },
        ],
        data: [],
        height: '400px'
      },
      finishFlagItems: [
        { code: 'N', codeName: '진행중' },
        { code: 'Y', codeName: '종료' },
      ],
      editable: true,
      listUrl: '',
      popupOptions: {
        isFull: true,
        target: null,
        title: '',
        visible: false,
        top: '',
        param: {},
        closeCallback: null,
      },
    };
  },
  beforeCreate() {},
  created() {},
  beforeMount() {
  },
  mounted() {
    this.init();
  },
  beforeDestroy() {
  },
  watch: {
    contentHeight() {
      let height = (this.contentHeight - this.$refs['searchbox'].$el.offsetHeight - 50);
      if (height < 400) {
        height = 500;
      }
      this.grid.height = height + 'px'
    }
  },
  methods: {
    init() {
      // role setting
      this.editable = this.$route.meta.editable;
      // url setting
      this.listUrl = selectConfig.sop.scm.construction.list.url;
      // code setting
      // list setting
      this.getList();
    },
    getList() {
      this.$http.url = this.listUrl;
      this.$http.type = 'GET';
      this.$http.param = this.searchParam;
      this.$http.request((_result) => {
        this.grid.data = _result.data;
      },);
    },
    // linkClick(row) {
    //   this.popupOptions.title = '공사 상세';
    //   this.popupOptions.param = {
    //     sopConstructionId: row.sopConstructionId,
    //   };
    //   this.popupOptions.target = () => import(`${'./constructionDetail.vue'}`);
    //   this.popupOptions.visible = true;
    //   this.popupOptions.closeCallback = this.closePopup;
    // },
    // closePopup() {
    //   this.popupOptions.target = null;
    //   this.popupOptions.visible = false;
    //   this.getList();
    // },
    select() {
      let selectData = this.$refs['table'].selected;
      if (!selectData || selectData.length === 0) {
        window.getApp.$emit('ALERT', {
          title: '안내', // 안내
          message: '공사를 지정하세요.', // 공사를 지정하세요.
          type: 'warning', // success / info / warning / error
        });
      } else {
        this.$emit('closePopup', selectData);
      }
    },
  }
};
</script>
